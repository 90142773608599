import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import DiscountDetails from "./DiscountDetails";
import { useDispatch, useSelector } from "react-redux";
import FlashDatePicker from "../../Components/Common/FlashDatePicker";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { DISCOUNT } from "../../helpers/data_helper";
import Select from "react-select";

const Discount = () => {
  const dispatch = useDispatch();
  document.title = "Discount Details | " + process.env.REACT_APP_NAME;
  // const [leftFilter, setLeftFilter] = useState(false);    
  const [mealPeriod, setMealPeriod] = useState(0);  
  const [filtermealperiod, setFiltermealperiod] = useState([]);
  const changeMealPeriod = (value) => {
    setMealPeriod(value);
  };

  const [serveList, setserveList] = useState([]);
  const [selectedServeList, setSelectedServeList] = useState(null);
  const [filterserveList, setFilterserveList] = useState([]);
  const changeServerList = (value) => {
    setSelectedServeList(value);
    let selVal= [];
    value.map((item) => {
      selVal.push(item.value);
    });
    setserveList(selVal);
  };

  const [discountCodes, setdiscountCodes] = useState([]);
  const [selectedDiscountCodes, setSelectedDiscountCodes] = useState(null);
  const [filterdiscountCodes, setFilterdiscountCodes] = useState([]);
  const changeDiscountCodes = (value) => {
    setSelectedDiscountCodes(value);
    let selVal= [];
    value.map((item) => {
      selVal.push(item.value);
    });
    setdiscountCodes(selVal);
  };

  const [filterDateList, setFilterDateList] = useState([]);
    
  const {
    filterData,
    filterAjax
  } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
  }));

  const [leftFilter, setLeftFilter] = useState(
    filterData.flash_date?.find((date) => date.default) || false
  );

  useEffect(() => {  
    if (filterData.serverList) {
      setFilterserveList(filterData.serverList);
    }
    if (filterData.mealperiod) {
      setFiltermealperiod(filterData.mealperiod);
    }
    if (filterData.flash_date) {
      setLeftFilter((prev) =>
        prev || filterData.flash_date.find((date) => date.default)
      );
      setFilterDateList(filterData.flash_date);
    }
  }, [filterAjax, filterData]);  

  useEffect(() => {          
          postDataAPI({
              serviceName: DISCOUNT.serviceName,
              op: DISCOUNT.op_discount_lists,
              leftFilter: leftFilter,
              mealPeriod: mealPeriod,
              page: 0,
              server_id: serveList,
              discount_id: discountCodes,
          }).then((response) => {              
              if (response.status === "ok") {
                  if (discountCodes.length === 0) {
                    const discountListArr = [];
                    response.data.discount_list.map((item) => {
                      discountListArr.push({
                        value: item.value,
                        label: item.lable
                      });
                    });
                    setFilterdiscountCodes(discountListArr);
                  }

                  if (serveList.length === 0) {
                    const serverListArr = [];
                    response.data.server_list.map((item) => {
                      serverListArr.push({
                        value: item.value,
                        label: item.lable
                      });
                    });
                    setFilterserveList(serverListArr);
                  }
                  //setFilterserveList(response.data.server_list);
              }else{
                console.log(response.data.data);
              }
          });
    }, [mealPeriod, leftFilter]);

  let date = new Date();
  date.setDate(date.getDate() - 1);  

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Discount Details" pageTitle="Discount Details"  />
          <form className="mb-3">
            <Row>
              <Col className="mb-2 mb-lg-0" sm={12} lg={3}>
              <FlashDatePicker
                  filterDateList={filterDateList}
                  activeDate={leftFilter}
                  setActiveDate={setLeftFilter}
                  posEnd={false}
                />
              </Col>
              <Col sm={12} lg={3}>
                <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={(event) => changeMealPeriod(event.target.value)}
                >
                  <option value="0">All Meal Periods</option>
                  {!filterAjax &&
                    filtermealperiod.length > 0 &&
                    filtermealperiod.map((item) => {
                      return (
                        <option key={item.sp_id} value={item.sp_id}>
                          {item.title + ' (' + item.start_time + ' To ' + item.end_time +')'}
                        </option>
                      );
                    })}
                </select>
              </Col>
              <Col sm={12} lg={3}>
                <div className="mb-3">
                <Select
                  value={selectedDiscountCodes}
                  isMulti={true}
                  onChange={(value) => {
                    changeDiscountCodes(value);
                  }}
                  options={filterdiscountCodes}
                  placeholder="All Discount Codes"
                />
                </div>
                  {/* <option value="0">All Discount Codes</option>
                  {!filterAjax &&
                    filterdiscountCodes.length > 0 &&
                    filterdiscountCodes.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.lable}
                        </option>
                      );
                    })} */}
              </Col>
              <Col sm={12} lg={3}>
                {/* <select
                  className="form-select"
                  value={serveList}
                  id="serveList"
                  onChange={(event) => changeServerList(event.target.value)}
                > */}
                <Select
                  value={selectedServeList}
                  isMulti={true}
                  onChange={(value) => {
                    changeServerList(value);
                  }}
                  options={filterserveList}
                  placeholder="All servers"
                />
                 
              </Col>              
            </Row>
          </form>
          <Row>
            <Col xl={12}>
                <DiscountDetails mealPeriod={mealPeriod} leftFilter={leftFilter} discountCodes={discountCodes} serveList={serveList} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Discount;
