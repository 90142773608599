/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState, useMemo } from "react";
import Loader from "../../Components/Common/Loader";
import { Col, Row, Card, CardHeader, CardBody, Button, Dropdown, DropdownItem, DropdownMenu, ButtonGroup, Input, Label, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { DISCOUNT } from "../../helpers/data_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import {
    currencyFormatWithAlert,
    currencyFormatWithAlertNoSymbol,
    currencyFormatWithoutDecimal,
    numberFormat,
    currencyFormat
} from "../../helpers/api_helper";

const DiscountDetails = ({ mealPeriod, leftFilter, discountCodes, serveList }) => {

    const columnDef = [
        {
            name: 'Date',
            key: 'row_dt'
        },{
            name: 'Discount',
            key: 'discount'
        },
        {
            name: 'Check',
            key: 'check',
            sort: false
        },
        {
            name: 'Item',
            key: 'item'
        },
        {
            name: 'Qty',
            key: 'qty'            
        },
        {
            name: 'Rate',
            key: 'rate',
            sort: false
        },        
        {
            name: 'Discount',
            key: 'discount_amt',
            sort: false
        },
        {
            name: 'Revenue',
            key: 'revenue',
            sort: false
        },       
        {
            name: 'Server',
            key: 'server',
        },
        {
            name: 'Reason',
            key: 'reason',
        }        
    ];    
    
    const [selectedPage, setSelectedPage] = useState(0);    
    const [pageCount, setPageCount] = useState(0);
    const [sellingItemAjax, setSellingItemAjax] = useState(true);
    const [sellingItem, setSellingItem] = useState([]);
    const [sortColumn, setSortColumn] = useState('');
    const [sortColumnType, setSortColumnType] = useState('ASC');
    const [limitdroddown, setlimitdroddown] = useState('50');        
    const [showColumns, setShowColumns] = useState(columnDef.map((column) => column.key));
    const [isOpen, setIsOpen] = useState(false);
   
    useEffect(() => {
        setSellingItemAjax(true);
        postDataAPI({
            serviceName: DISCOUNT.serviceName,
            op: DISCOUNT.op_discount_lists,
            leftFilter: leftFilter,
            mealPeriod: mealPeriod,
            page: selectedPage,
            server_id: serveList,
            discount_id:discountCodes,           
            // sort_column: sortColumn,
            // sort_type: sortColumnType   
        }).then((response) => {
            setSellingItemAjax(false);
            if (response.status === "ok") {
                setSellingItem(response.data.data);                
                setPageCount(Math.ceil(response.data.totalData / limitdroddown));
            }else{
                setPageCount(0);
            }
        });
    }, [mealPeriod, leftFilter, serveList, discountCodes, selectedPage]);


    // Handle LImit
    const handleLimit = (e) => {
        console.log(e.target.value);
        setlimitdroddown(e.target.value);
    }

    /******** Export ********/
    const handleExport = (e) => {
        postDataAPI({
            serviceName: DISCOUNT.serviceName,
            op: DISCOUNT.op_discount_lists,
            leftFilter: leftFilter,
            mealPeriod: mealPeriod,
            page: selectedPage,
            server_id: serveList,  
            discount_id:discountCodes,       
            export: 1,
            exportColumns: showColumns, 
            // sort_column: sortColumn,
            // sort_type: sortColumnType   
        }).then((response) => {
            setSellingItemAjax(false);
            if (response.status === "ok") {
                window.open(response.data.data.filename);
            }else{
                console.log(response);
            }
        });
    };

    /******** Show and Hide Columns *********/
    const columnDropdownRef = createRef();
    const toggleDropdown = (e) => {
        if (columnDropdownRef.current.contains(e.target)) return;
        setIsOpen(!isOpen);
    };
    const handleColumnHideShow = (event, columnName) => {
        event.stopPropagation();
        if (showColumns.includes(columnName)) {
            setShowColumns(showColumns.filter((column) => column != columnName))
        } else {
            setShowColumns([...showColumns, columnName]);
        }        
    }

    /******** Sorting *********/
    const handleColumnSort = (column) => {
        setSortColumnType(sortColumn === column.key && sortColumnType === 'ASC' ? 'DESC' : 'ASC');
        setSortColumn(column.key);
    }
    const filteredColumns = useMemo(() => columnDef.filter(column => showColumns.includes(column.key)), [showColumns, columnDef]);
    /*********************/
    
    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Discount Check Listing</h4>
                <div className="flex-shrink-0">
                    <div className="d-flex">

                        <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-3" style={{ borderRadius: '0.25rem', marginRight: '15px' }}>
                            <ButtonGroup>
                                <Button color="info">Columns</Button>
                                <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                            </ButtonGroup>
                            <DropdownMenu className="dropdown-menu-end" end={true}>
                                <div ref={columnDropdownRef}>
                                    {columnDef.map((column) => (
                                        <DropdownItem onClick={(event) => { handleColumnHideShow(event, column.key) }} key={column.key}>
                                            <div className="form-check mb-1">
                                                <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns.includes(column.key)} value={column.key} />
                                                <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                                            </div>
                                        </DropdownItem>
                                    ))}
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        {/* <span className="p-2">Show</span>
                        <select defaultValue="50" className="form-select" id="selling_item_limit" style={{ cursor: 'pointer' }} onChange={handleLimit}>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>
                        <span className="p-2">entries</span> */}

                        <Button
                            className="ms-3"
                            color="primary"
                            onClick={handleExport}
                        >
                            Export
                        </Button>

                    </div>
                </div>
            </CardHeader>
            <CardBody>
                {sellingItemAjax && <Loader />}
                <div className={sellingItemAjax ? "opacity-0" : ""}>
                    {sellingItemAjax && <Loader />}
                    <div
                        className={
                            sellingItemAjax
                                ? "opacity-0 table-responsive table-card"
                                : "table-responsive table-card"
                        }
                        style={{ maxHeight: '500px', overflow: 'auto' }}
                    >
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                            <thead className="text-muted table-light">
                                <tr style={{ position: 'sticky', top: '-2px', zIndex: 'auto' }}>
                                    {filteredColumns.map((column) => (
                                        // eslint-disable-next-line no-undef
                                        <th className={column.className} key={column.key} scope="col">{column.name} {column.sort && <i className="las la-arrow-down" style={{ cursor: 'pointer', transform: (sortColumn == column.key && sortColumnType == 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)') }} onClick={() => handleColumnSort(column)}></i>}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {(sellingItem || []).map((item, key) => (
                                    <tr key={key}>                                     
                                        {showColumns.includes('row_dt') && <td>{item.row_dt}</td>}
                                        {showColumns.includes('discount') && <td>{item.discount}</td>}
                                        {showColumns.includes('check') && <td>{item.check}</td>}
                                        {showColumns.includes('item') && <td>{item.item}</td>}
                                        {showColumns.includes('qty') && <td>{currencyFormatWithAlertNoSymbol(item.qty)}</td>}
                                        {showColumns.includes('rate') && <td>{currencyFormatWithAlert(item.rate)}</td>}                                    
                                        {showColumns.includes('discount_amt') && <td>{currencyFormatWithAlert(item.discount_amt)}</td>}
                                        {showColumns.includes('revenue') && <td>{currencyFormatWithAlert(item.revenue)}</td>}                                      
                                        {showColumns.includes('server') && <td>{item.server}</td>}
                                        {showColumns.includes('reason') && <td>{item.reason}</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-5 float-end">
                        <nav>
                            <CustomPagination
                                pageCount={pageCount}
                                setSelectedPage={setSelectedPage}
                            />
                        </nav>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default DiscountDetails;
