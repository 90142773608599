/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import ForeCastPreviewCardHeader from "../../Components/Common/ForeCastPreviewCardHeader";
import { FlashTotalRevChart } from "./FlashTotalRevChart";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { FLASH } from "../../helpers/data_helper";

const ForeCastTotalRev = ({ mealPeriod, leftFilter }) => {
    const [forecastAjax, setForecastAjax] = useState(false);
    const [start_dt, setstart_dt] = useState("");
    const [end_dt, setend_dt] = useState("");
    const [forecastData, setForecastData] = useState({
        actual: [],
        budget: [],
        ly: [],
        forecast: [],
        months: []
    });

    let date = new Date();
    date.setDate(date.getDate() - 1);

    let date_second = new Date();
    date_second.setDate(date_second.getDate() - 1);
 
    useEffect(()=>{
        setForecastAjax(true);
        postDataAPI({
            serviceName: FLASH.serviceName,
            op: FLASH.op_flash_total_revenue,
            leftFilter: leftFilter,
            mealPeriod: mealPeriod
        }).then((response) => {
            setForecastAjax(false);
            if (response.status === "ok") {                                                
                setForecastData({
                    actual: response.data.data.actual || [],  
                    budget: response.data.data.budget || [],
                    ly: response.data.data.ly || [],
                    forecast: response.data.data.forecast || [],
                    months: response.data.data.months || []  
                });      
                setstart_dt(response.data.data.start_dt);
                setend_dt(response.data.data.end_dt);
            } else {
                // console.log(response);
            }
        });
        
    }, [mealPeriod, leftFilter]);    

    return (
        <React.Fragment>
            <Card>
                <CardHeader className="align-items-center">
                    <h4 className="card-title mb-0">Total Revenue</h4>
                </CardHeader>
                <CardBody>
                {forecastAjax && <Loader />}
                <div className={forecastAjax ? "opacity-0" : ""}>
                    <div className="live-preview">
                    <FlashTotalRevChart
                        forecastData={forecastData}
                        dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info", "--vz-dark"]'
                        start_dt={start_dt}
                        end_dt={end_dt}
                    />
                    </div>
                    {/* <div className="d-none code-view">
                    <div className="table-responsive table-card">
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                        <thead className="text-muted table-light">
                            <tr>
                                <th scope="col">Period</th>
                                <th scope="col">Actual</th>
                                <th scope="col">Budget</th>
                                <th scope="col">Last Year</th>
                                <th scope="col">Forecast</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(forecastData.actual || []).map(
                            (item, key) => {
                                date.setDate(date.getDate() + 1);
                                return (
                                <tr key={key}>
                                    <td>
                                    {date.toLocaleString("en-us", {
                                        day: "2-digit",
                                    }) +
                                        " " +
                                        date.toLocaleString("en-us", {
                                        month: "short",
                                        })}
                                    </td>
                                    <td>{item}</td>
                                    <td>
                                    {forecastData.budget[key] ?? ""}
                                    </td>
                                    <td>
                                    {forecastData.ly[key] ?? ""}
                                    </td>
                                    <td>
                                    {forecastData.forecast[key] ?? ""}
                                    </td>
                                </tr>
                                );
                            }
                            )}
                        </tbody>
                        </table>
                    </div>
                    </div> */}
                </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ForeCastTotalRev;
